import { useEffect, type FC, useState } from "react";
import { Header, HeaderRoutes } from "alicia-design-system";
import { useTranslation } from "react-i18next";
import { rem } from "polished";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguageTranslation } from "hooks/useLanguageTranslation";
import { usePartnerStore } from "store/partner";
import {
  InsuranceFlow,
  // SupportActionEnum
} from "src/types";
import { useProductsStore } from "store/products";
import { useTrengo } from "hooks/useTrengo";
import Cookies from "universal-cookie";
import {
  isInsuranceCalculatorRoute,
  isInsuranceCheckInRoute,
} from "@utils/getFlowPaths";
import { useColors } from "hooks/useColors";
import { useAuthStore } from "store/authentication";
import { navigateTo } from "services/navigateTo";

// initialize cookies
const cookies = new Cookies(null, {
  path: "/",
});

export const PrivateHeader: FC<PrivateHeaderProps> = ({
  showHeaderNav = false,
  isInCheckoutFlow = false,
}) => {
  // hooks
  const { t } = useTranslation();
  const location = useLocation();
  const { getColor } = useColors();
  const navigation = useNavigate();
  const { openTrengo } = useTrengo();
  // stores
  const { user, insuranceFlow, user_quote } = useAuthStore();
  const {
    brand,
    // support_email,
    // support_phone_number,
    enable_visual_effects,
    name,
  } = usePartnerStore();
  const { selectedLanguage, languages, changeSelectedLanguage } =
    useLanguageTranslation();
  const { isPastPoliciesAvailable, products } = useProductsStore();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const insuranceCheckInRoute = isInsuranceCheckInRoute();
  // states
  const [headerNavList, setHeaderNavList] = useState<HeaderRoutes>();

  const host: string = window.location.host;
  const [partner] = host.split(".");
  const eligibleProducts = products?.filter(
    (product) => product.is_eligible && product.type === "aov"
  );

  const getUserMenuSelectedOption = (): string | undefined => {
    if (
      location.pathname.includes("user/policies") ||
      location.pathname.includes("policy")
    ) {
      return "user-policies";
    } else if (location.pathname.includes("user/details")) {
      return "user-details";
    } else if (location.pathname.includes("user/invoices")) {
      return "user-invoices";
    } else if (location.pathname.includes("products/available-products")) {
      return "available-insurances";
    } else {
      return undefined;
    }
  };

  const getHeaderMenuSelectedOption = (): string | undefined => {
    if (
      location.pathname.includes("user/policies") ||
      location.pathname.includes("policy")
    ) {
      return "/user/policies";
    } else {
      return location.pathname;
    }
  };

  useEffect(() => {
    const setupHeaderNavList = () => {
      const tempNavList: HeaderRoutes = {
        navList: [
          {
            id: "/products/available-products",
            title: t("header.routes.availableInsurances"),
            route: "/products/available-products",
            isMobileOnly: true,
          },
          {
            id: "/user/policies",
            title: t("header.routes.myInsurances"),
            route: "/user/policies",
            isMobileOnly: true,
          },
          {
            id: "/user/details",
            title: t("header.routes.myDetails"),
            route: "/user/details",
            isMobileOnly: true,
          },
          {
            id: "/user/invoices",
            title: t("header.routes.myInvoices"),
            route: "/user/invoices",
            isMobileOnly: true,
          },
          {
            id: "/user/actions",
            title: t("header.routes.myInbox"),
            route: "/user/inbox",
            isMobileOnly: true,
            count: eligibleProducts?.length ? 1 : undefined,
          },
        ],
        fontSize: rem(18),
        fontWeight: 500,
        textColor: getColor("text"),
      };

      setHeaderNavList(tempNavList);
    };
    setupHeaderNavList();
  }, [name, isPastPoliciesAvailable, selectedLanguage?.value]);

  const getLogoClickPath = () => {
    if (user_quote) {
      return "#";
    } else if (insuranceCalculatorRoute) {
      return "/insurance-calculator";
    } else if (insuranceCheckInRoute) {
      return "/insurance-checkin-welcome";
    } else {
      return "/welcome";
    }
  };

  const onSupportClick: any = () => {
    openTrengo("chat");
  };

  return (
    <Header
      enableVisualEffects={enable_visual_effects}
      hideMobileMenu={
        t("deeplink.dismiss") !== "deeplink.dismiss"
          ? false
          : !headerNavList?.navList.length
      }
      showLanguageSelector={!isInCheckoutFlow && languages.length > 1}
      headerMenuColors={{
        mobileMenuBackgroundColor: getColor("support-menu-background"),
        mobileMenuTextColor: getColor("support-menu-text"),
        menuBackgroundColor: getColor("support-menu-background"),
        menuTextColor: getColor("support-menu-text"),
        itemColor: getColor("support-menu-background"),
        itemHoverColor: getColor("support-menu-hover"),
        itemTextColor: getColor("support-menu-text"),
        itemHoverTextColor: getColor("support-menu-text-hover"),
      }}
      languageMenuitemColorHover={getColor("support-menu-text-hover")}
      textColor={getColor("text") ?? "text"}
      imageSrc={brand?.logo_primary ?? ""}
      imageFallbackSrc={brand?.logo_secondary ?? ""}
      showSupport
      onLogoClick={() => navigation(getLogoClickPath())}
      languageMenu={{
        languages: languages,
        language: selectedLanguage,
      }}
      headerActiveRoute={getHeaderMenuSelectedOption()}
      setLanguage={(e) => {
        changeSelectedLanguage(e.value);
      }}
      showHeaderNavigation={showHeaderNav}
      headerRoutes={
        headerNavList?.navList && headerNavList?.navList.length > 0
          ? headerNavList
          : undefined
      }
      headerNavClick={(e) => navigation(e)}
      steps={[]}
      showSteps={false}
      showProgressBar={false}
      showCurrentStep
      containerZIndex={3}
      supportMenu={{
        onOpenSupport: onSupportClick,
        supportMenuTitle: t("support.title") as string,
        supportMenuOptions: [],
        backgroundColor: getColor("support-menu-background"),
        itemColor: getColor("support-menu-background"),
        itemHoverColor: getColor("support-menu-hover"),
        itemTextColor: getColor("support-menu-text"),
        itemHoverTextColor: getColor("support-menu-text-hover"),
        onSupportMenuSelect: () => null,
      }}
      backgroundColor="transparent"
      showUser={
        insuranceFlow === InsuranceFlow.DEFAULT &&
        !location.pathname.includes("checkout")
      } //always show user menu
      mobileBack={
        t("deeplink.dismiss") !== "deeplink.dismiss"
          ? {
              title: t("header.routes.backToApp"),
              onClick: () =>
                cookies.get("platform") !== "web"
                  ? navigateTo(t("deeplink.dismiss"))
                  : navigateTo(t("header.routes.backToWeb")),
              textColor: getColor("support-menu-text"),
            }
          : undefined
      }
      userMenu={{
        userMenuTitle: user?.first_name,
        userMenuOptions: [
          {
            label: t("header.routes.availableInsurances"),
            value: "available-insurances",
          },
          {
            label: t("header.routes.myInsurances"),
            value: "user-policies",
          },
          {
            label: t("header.routes.myDetails"),
            value: "user-details",
          },
          {
            label: t("header.routes.myInvoices"),
            value: "user-invoices",
          },
          {
            label: t("header.routes.myInbox"),
            value: "user-inbox",
          },
          ...[
            partner === "knab"
              ? {
                  label: t("header.routes.backToLabel"),
                  value: "https://www.knab.nl",
                }
              : undefined,
          ],
        ],
        backgroundColor: getColor("support-menu-background"),
        itemColor: getColor("support-menu-background"),
        itemHoverColor: getColor("support-menu-hover"),
        itemTextColor: getColor("support-menu-text"),
        itemHoverTextColor: getColor("support-menu-text-hover"),
        onUserMenuSelect: (e) => {
          switch (e) {
            case "available-insurances":
              navigation("/products/available-products");
              break;
            case "user-policies":
              navigation("/user/policies");
              break;
            case "user-details":
              navigation("/user/details");
              break;
            case "user-invoices":
              navigation("/user/invoices");
              break;
            case "user-inbox":
              navigation("/user/inbox");
              break;
            case "https://www.knab.nl":
              window.location.href = "https://www.knab.nl";
              break;
            default:
              break;
          }
        },
        selectedOption: getUserMenuSelectedOption(),
      }}
    />
  );
};

interface PrivateHeaderProps {
  showHeaderNav?: boolean;
  isInCheckoutFlow?: boolean;
}
