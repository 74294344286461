import { create } from "zustand";
import Cookies from "universal-cookie";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import {
  AddressType,
  AuthDetailsType,
  AuthResponseType,
  InsuranceFlow,
  PartnerQueryType,
  ProfessionsResultType,
  PutProfessionType,
  PutUserCocType,
  PutUserDOBType,
  PutUserPaymentConcentType,
  UserAddressTypeEnum,
  UserType,
} from "../types/types";
import { refreshUserAuthenticate, userAuthenticate } from "services/api/auth";
import {
  createUserAddress,
  getUserDetails,
  requestUserLogin,
  updateUserAddress,
  updateUserDetails,
} from "src/services/api/user";
import { AUTH_STORE } from "../constants/constant";
import { ADMIN_COOKIE_TOKEN } from "src/constant";
import { getCookieURL } from "@utils/getCookieURL";
import { generateAgosUrl } from "@utils/generateAgosUrl";
import { getEnvironment } from "@utils/getEnvironment";

// initialize cookies
const cookies = new Cookies(null, {
  path: "/",
  domain: getCookieURL(),
});

const initialAuthState = {
  authLoading: false,
  authenticated: false,
  user: undefined,
  external_id: undefined,
  access_token: undefined,
  refresh_token: undefined,
  userError: undefined,
  insuranceFlow: InsuranceFlow.DEFAULT,
  admin: undefined,
  user_quote: undefined,
  broker_quote: undefined,
};

export const useAuthStore = create(
  devtools(
    persist<AuthDetailsType>(
      (set, get) => ({
        ...initialAuthState,
        setUserToken: async (queryParams: PartnerQueryType) => {
          try {
            set(() => ({
              authLoading: true,
            }));
            const result: AuthResponseType = await userAuthenticate(
              queryParams
            );
            set(() => ({
              access_token: result.access,
              refresh_token: result.refresh,
              ...(queryParams.external_id && {
                external_id: queryParams.external_id,
              }),
              authenticated: true,
              user_quote: queryParams?.quote_id ?? undefined,
              broker_quote: queryParams?.broker_quote ?? undefined,
            }));
          } catch (error) {
            set(() => ({
              authenticated: false,
            }));
            throw error;
          } finally {
            set(() => ({
              authLoading: false,
            }));
          }
        },
        refreshUserToken: async () => {
          try {
            const result: AuthResponseType = await refreshUserAuthenticate(
              String(get().refresh_token)
            );
            set(() => ({
              access_token: result.access,
              authenticated: true,
            }));
          } catch (error) {
            set(() => ({
              authenticated: false,
            }));
            throw error;
          }
        },
        adminAuthenticate: async (adminToken) => {
          try {
            const { token, name, returnURL } = adminToken;
            const result: AuthResponseType = await refreshUserAuthenticate(
              token as string,
              true
            );
            set(() => ({
              admin: {
                access_token: result.access,
                refresh_token: token,
                name,
                returnURL,
              },
            }));
          } catch (error) {
            set(() => ({
              authenticated: false,
            }));
            throw error;
          }
        },
        refreshAdminToken: async () => {
          try {
            const result: AuthResponseType = await refreshUserAuthenticate(
              String(get().admin?.refresh_token),
              true
            );
            set(() => ({
              admin: {
                access_token: result.access,
              },
            }));
          } catch (error) {
            set(() => ({
              authenticated: false,
            }));
            throw error;
          }
        },
        getUser: async () => {
          try {
            const user: UserType = await getUserDetails();
            set(() => ({
              user,
            }));
            return user;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        clearAuth: () => {
          set(() => initialAuthState);
        },
        clearBroker: () => {
          set(() => ({
            user_quote: undefined,
            broker_quote: undefined,
          }));
          get().clearAuth();
          window.location.replace(generateAgosUrl(getEnvironment()));
        },
        clearAdminAuth: () => {
          const returnURL = get().admin?.returnURL;
          set(() => ({
            admin: undefined,
          }));
          cookies.get(ADMIN_COOKIE_TOKEN) && cookies.remove(ADMIN_COOKIE_TOKEN);
          returnURL && window.location.replace(returnURL);
        },
        signIn: async (email: string) => {
          try {
            const user: UserType = await requestUserLogin(
              email.toLocaleLowerCase()
            );
            set(() => ({
              user,
            }));
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        putUserProfession: async (data: ProfessionsResultType) => {
          try {
            await updateUserDetails({
              profession: data.value,
            } as PutProfessionType);
            const user = get().user;
            if (user) {
              user.profession = { id: data.value, name: data.label ?? "" };
            }
            set(() => ({
              user,
            }));
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        putUserCoc: async (data: PutUserCocType) => {
          try {
            await updateUserDetails(data as PutUserCocType);
            let user = get().user;
            if (user) {
              user = { ...user, ...data };
            }
            set(() => ({
              user,
            }));
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        putUserPayment: async (data: PutUserPaymentConcentType) => {
          try {
            await updateUserDetails(data as PutUserPaymentConcentType);
            let user = get().user;
            if (user) {
              user = { ...user, ...data };
            }
            set(() => ({
              user,
            }));
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        putUserBirthDate: async (data: PutUserDOBType) => {
          try {
            await updateUserDetails(data as PutUserDOBType);
            let user = get().user;
            if (user) {
              user = { ...user, ...data };
            }
            set(() => ({
              user,
            }));
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        manageUserAddress: async (
          data: AddressType,
          type: UserAddressTypeEnum
        ) => {
          try {
            const user = get().user;
            const addressId =
              type === UserAddressTypeEnum.COMPANY
                ? user?.company_address?.id
                : user?.personal_address?.id;
            if (addressId && typeof addressId === "string") {
              await updateUserAddress(data, addressId);
            } else {
              await createUserAddress({ ...data, type });
            }
            await get().getUser();
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        setInsuranceFlow: (insuranceFlow: InsuranceFlow) => {
          set(() => ({
            insuranceFlow,
          }));
        },
        clearUserQuote: () => {
          set(() => ({
            user_quote: undefined,
            broker_quote: undefined,
          }));
        },
      }),
      {
        name: AUTH_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
