export const generateAgosUrl = (
  env: "qa" | "stage" | "production" | "development"
) => {
  const claimHOST = {
    development: "http://localhost:3001/quotes",
    qa: "https://agos.qa.aliciatech.n/quotes",
    stage: "https://agos.stage.aliciatech.n/quotes",
    production: "https:/agos.alicia.insure/quotes",
  };

  return claimHOST[env];
};
